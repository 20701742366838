@use '../abstract' as *

.realisation
	position: relative
	
	border-radius: var(--border-radius-small-value)
	overflow: hidden
	width: 100%
	aspect-ratio: 7 / 8
	max-height: 80vh
	
	color: white
	
	display: flex
	flex-direction: column
	justify-content: flex-end
	gap: 4rem
	
	text-decoration: none
	
	isolation: isolate
	
	.realisation_image
		object-fit: cover
		z-index: -1
	
	.realisation_title
		@include z-font-heading-desktop-h3
		text-transform: uppercase
		position: relative
		
		padding: 4rem 3.5rem
		
		@include zi-media
			padding: 2rem
		
		&:before
			content: ''
			position: absolute
			inset: 0
			background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, 0.5) 100%)
			
			z-index: -1
