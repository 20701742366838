@use '../abstract' as *

.bottom_section
	position: relative
	
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	gap: 4rem
	
	min-height: 70vh
	
	isolation: isolate
	
	@include zi-media
		min-height: 50vh
	
	.background_image
		position: absolute
		inset: 0
		
		width: 100%
		height: 100%
		object-fit: cover
		
		z-index: -1
	
	.title
		@include z-font-heading-desktop-h1-regular
		text-transform: uppercase
		text-align: center
		
		max-width: 20ch
	
	.link
		@include z-button
