@use '../../abstract' as *


.presentation_wrapper
	--svg-left: 44.5%
	--svg-top: 10%

	position: relative
	height: 200vh
	overflow: hidden

	background: transparent !important


	.presentation_container
		height: 100vh
		position: relative
		
		.large_image
			position: fixed
			z-index: -10
			top: 0
			left: 0
			right: 0
			object-fit: cover
			object-position: 50% 40%
			height: 100%
			
			width: 100%
		
		.text
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			transform-origin: var(--svg-left) var(--svg-top)
			
			display: flex
			flex-direction: row
			justify-content: center
			align-items: stretch
			gap: 0
			
			width: 100%
			height: fit-content

			span
				background-color: #1C31A6
				flex: 1
			
			svg
				height: fit-content
				width: 100%
				//max-width: 800px
				margin: 0 -1px
				
				rect
					fill: $color-bleu-500
				
				path
					fill: $color-bleu-500
			
			&:before, &:after
				--height-value: 800%
				content: ''
				position: absolute
				bottom: calc(100% - 1px)
				top: calc(var(--height-value) * -1)
				left: 0
				right: 0
				width: 100%
				background-color: $color-bleu-500
			
			&:after
				top: calc(100% - 1px)
				bottom: calc(var(--height-value) * -1)
		
		// DEBUG
		//&:before
		//	content: ''
		//	position: absolute
		//	top: var(--svg-top)
		//	left: var(--svg-left)
		//	transform: translate(-50%, -50%)
		//
		//	width: 1rem
		//	height: 1rem
		//	background-color: $color-orange-500
		//
		//	z-index: 100
