@use '../abstract' as *

@mixin z-footer-lines
	position: relative
	
	&:before
		content: ''
		position: absolute
		inset: .5px
		box-shadow: 0 0 0 1px var(--footer-color)
		pointer-events: none

.footer
	position: relative
	
	padding-block: 4rem
	
	// Remove border lines on the left and right
	max-width: 100%
	overflow: hidden
	
	--footer-background-color: #{$color-bleu-500}
	--footer-color: #{$color-orange-500}
	
	background-color: var(--footer-background-color)
	color: var(--footer-color)
	
	&.inverted_colors
		--footer-background-color: #{$color-orange-500}
		--footer-color: #{$color-bleu-500}
	
	@include z-font-body
	
	@include zi-media('<=', mobile)
		//padding-block: 2rem
		padding-block: 0
	
	.footer_content
		@include z-grid(13)
		gap: 0
		grid-template-rows: 1fr auto
		--footer-padding-value: 2.5rem
		
		position: relative
		width: calc(100% + 2px)
		left: -1px
		
		.logo
			grid-column: 1 / span 4
			grid-row: 1 / span 2
			//padding: var(--footer-padding-value)
			padding: calc(var(--footer-padding-value) * 2)
			
			display: flex
			justify-content: center
			align-items: center
			
			@include z-footer-lines
			
			svg
				fill: var(--footer-color)
				max-width: min(50vw, 100%)
				height: auto
		
		.footer_menu, .footer_other_links, .footer_contact
			grid-column: span 3
			display: flex
			flex-direction: column
			gap: 1rem
			
			padding: var(--footer-padding-value)
			
			@include z-footer-lines
			
			.title
				@include z-font-body
				font-weight: 700
				text-transform: uppercase
			
			.links
				list-style-type: none
				margin: 0
				padding: 0
				
				display: flex
				flex-direction: column
				gap: 1rem
				
				a
					text-decoration: none
		
		.footer_bottom
			grid-column: span 9
			
			padding: var(--footer-padding-value)
			
			display: flex
			flex-direction: row
			justify-content: space-between
			align-items: center
			gap: var(--footer-padding-value)
			
			span
				font-size: 1rem
			
			@include z-footer-lines
		
		@include zi-media
			@include z-grid(12)
			gap: 0
			
			--footer-padding-value: 2rem
			
			.logo
				grid-column: 1 / -1
				display: flex
				justify-content: center
				align-items: center
			
			.footer_menu, .footer_other_links, .footer_contact
				grid-column: span 4
			
			.footer_bottom
				grid-column: 1 / -1
		
		@include zi-media('<=', mobile)
			//--footer-padding-value:
			.footer_menu, .footer_other_links, .footer_contact
				grid-column: 1 / -1
			
			.footer_menu
				.links
					display: grid
					grid-template-columns: repeat(2, 1fr)
			
			.footer_other_links
				.links
					display: grid
					grid-template-columns: repeat(2, 1fr)
					
					li:last-child
						grid-column: 1 / -1
			
			.footer_bottom
				display: flex
				flex-direction: column
				
				align-items: center
				
				text-align: center
