@use 'abstract' as *

.page_content
	> *
		background-color: white

	.hero
		display: grid
		grid-template-rows: 1fr auto
		
		gap: 0
		
		height: 100vh

		.hero_image_container
			position: relative
			
			width: 100%
			height: auto
			
			border-radius: 0 0 var(--border-radius-value) var(--border-radius-value)
			overflow: hidden
			
			img
				width: 100%
				height: 100%
				object-fit: cover
		
		.hero_content
			display: flex
			flex-direction: row
			gap: 8rem
			justify-content: space-between
			
			@include z-padding
			// TODO: Verify it's better with a lower padding block (last one was 4rem)
			padding-block: 2rem
			
			@include zi-media
				gap: 4rem
				
				flex-direction: column
			
			@include zi-media('<=', mobile)
				padding: 2rem 1rem
				gap: 2rem
			
			.hero_content_left
				flex: 2
				width: fit-content
				display: flex
				flex-direction: column
				gap: .5rem
				
				.hero_title
					padding-top: 2rem
					@include z-font-heading-desktop-display-bold
					text-transform: uppercase
					color: $color-orange-500
				
				.hero_subtitle
					@include z-font-heading-desktop-h1-regular
					color: $color-bleu-500
			
			.hero_content_right
				flex: 1
				
				display: flex
				flex-direction: column
				justify-content: space-between
				gap: .5rem
				min-width: min(40ch, 30%)
				
				.description
				
				.hero_arrow
					align-self: flex-end
	
	.your_project
		@include z-padding
		background-color: $color-bleu-50
		
		@include z-grid
		grid-template-rows: 1fr
		place-items: center
		
		@include z-grid-background
		
		max-height: 100vh
		overflow: hidden
		
		@include zi-media
			display: flex
			flex-direction: column

		@include zi-media('<=', mobile)
			max-height: none
		
		.your_project_left
			grid-column: span 5
			@include z-grid(5)
			
			.your_project_title
				grid-column: 1 / -1
				
				@include z-font-heading-desktop-h2-old-standard
				color: $color-bleu-500
				text-transform: uppercase
			
			.your_project_content
				grid-column: 2 / -1
				
				display: flex
				flex-direction: column
				gap: 2rem
				
				color: $color-bleu-900
				
				.your_project_content_citation
					@include z-font-old-standard
				
				.description
				
				@include zi-media('<=', mobile)
					grid-column: 2 / -1
		
		.your_project_right
			max-height: 100%
			overflow: hidden
			
			grid-column: 7 / -1
			width: 100%
			
			@include z-grid(6)
			grid-template-rows: 1fr 1fr
			
			// Background element
			position: relative
			
			&:before
				content: ''
				position: absolute
				inset: 8% 15% 8% 24%
				background-color: $color-orange-500
				border-radius: var(--border-radius-value)
			
			.your_project_image_container
				border-radius: var(--border-radius-value)
				overflow: hidden
				
				position: relative
				
				height: 100%
				width: 100%
				
				img
					width: 100%
					height: 100%
					object-fit: cover
				
				&:nth-child(1)
					grid-column: span 4
					align-self: flex-end
				
				&:nth-child(2)
					grid-column: span 2
				
				&:nth-child(3)
					grid-column: 2 / span 2
				
				&:nth-child(4)
					grid-column: span 3
					align-self: flex-start
	
	.realisations
		padding: var(--padding-inline-value)
		
		display: flex
		flex-direction: row
		gap: 4rem
		
		@include zi-media('>')
			padding-right: 0
		
		@include zi-media
			flex-direction: column
			gap: 2rem
		
		.left
			flex: 1
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			gap: 2rem
			
			.realisations_title
				@include z-font-heading-desktop-h2-bold
				color: $color-bleu-500
				
				@include zi-media('>')
					@include z-title-margin-second-line
				
				@include zi-media
					text-align: center
			
			.realisations_link
				@include z-button
		
		.right
			flex: 2
			display: flex
			flex-direction: row
			gap: 4rem
			
			max-width: 100%
			
			@include zi-media
				gap: 2rem
			
			@include zi-media('<=', mobile)
				flex-direction: column
				gap: 2rem
	
	.instagram
		@include z-padding
		padding-bottom: calc(2 * var(--padding-block-value))
		
		display: flex
		flex-direction: column
		gap: 4rem
		
		@include zi-media
		
		.instagram_top
			display: flex
			flex-direction: column
			align-items: center
			gap: 2rem
			
			.instagram_title
				@include z-font-heading-desktop-h2-bold
				color: $color-bleu-500
				text-align: center
			
			.instagram_link
				@include z-button
		
		.instagram_feed
			display: grid
			grid: 'image1 image2 image3' 1fr 'image1 image2 image3' 1fr 'image1 image2 image3' 1fr 'image1 image2 image6' 1fr 'image4 image2 image6' 1fr 'image4 image5 image6' 1fr 'image4 image5 image6' 1fr 'image4 image5 image6' 1fr / 1fr 1fr 1fr
			gap: 2rem
			
			min-height: 70vh
			
			.instagram_feed_image
				position: relative
				width: 100%
				height: 100%
				min-height: 200px
				
				border-radius: var(--border-radius-value)
				overflow: hidden
				
				@include zi-media
					min-height: 150px
				
				img
					object-fit: cover
				
				&:nth-child(1)
					grid-area: image1
				
				&:nth-child(2)
					grid-area: image2
				
				&:nth-child(3)
					grid-area: image3
				
				&:nth-child(4)
					grid-area: image4
				
				&:nth-child(5)
					grid-area: image5
				
				&:nth-child(6)
					grid-area: image6
			
			@include zi-media
				gap: 1rem
				grid: 'image1 image2' 1fr 'image1 image2' 1fr 'image1 image2' 1fr 'image3 image2' 1fr 'image3 image4' 1fr 'image3 image4' 1fr 'image5 image4' 1fr 'image5 image6' 1fr 'image5 image6' 1fr / 1fr 1fr
