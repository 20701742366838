@use '../abstract' as *

.header
	position: absolute
	inset: 0 0 auto
	z-index: 100

	display: flex
	flex-direction: row
	justify-content: space-between
	align-items: center
	gap: 2rem

	padding: 1rem 2rem

	color: $color-bleu-900

	.logo
		fill: $color-bleu-900

		@include zi-media
			max-height: 1.5rem
			width: auto

		@include zi-media('<=', tablet)
			max-height: 1.25rem

	.menu_container
		@include z-font-body

		.icon
			display: none

		.list
			display: flex
			flex-direction: row
			gap: 2rem

			margin: 0
			padding: 0
			list-style-type: none

			.menu_item
				.link
					text-decoration: none
					text-transform: uppercase

	@include zi-media('<=', tablet)
		.menu_container
			color: $color-bleu-900
			position: relative

			.icon
				display: flex

			.menu_wrapper
				position: fixed
				inset: 0

				background-color: rgba(0, 0, 0, .2)
				transition: opacity .2s ease 0s

				&:not(.opened)
					opacity: 0
					pointer-events: none

					transition: opacity .2s ease .1s

					.list
						transform: translateX(100%)

			.list
				pointer-events: all
				height: 100%
				width: 90%
				margin-left: auto

				background-color: white
				border-radius: var(--border-radius-small-value) 0 0 var(--border-radius-small-value)
				box-shadow: 0 0 1rem rgba(0, 0, 0, .2)

				transform: translateX(0)
				transition: transform .3s ease

				padding: 4rem 2rem

				display: flex
				flex-direction: column
				gap: 2rem

				@include z-font-body-large

	isolation: isolate

	&:before
		content: ''
		position: absolute
		inset: 0
		height: 100%
		background-color: white
		//opacity: .5

		z-index: -1

	&.white
		color: white

		&:before
			background-color: $color-bleu-500

		.logo
			fill: white
