//region Colors
$color-bleu-50: #E8EAF6
$color-bleu-300: #6775C3
$color-bleu-500: #1C31A6
$color-bleu-800: #0F1B5B
$color-bleu-900: #0C1546

// Orange
$color-orange-50: #FFF5E9
$color-orange-100: #FFDFBA
$color-orange-200: #FFD098
$color-orange-300: #FEBB6A
$color-orange-400: #FEAD4D
$color-orange-500: #FE9920
$color-orange-600: #E78B1D
$color-orange-700: #B46D17
$color-orange-800: #8C5412
$color-orange-900: #6B400D
//endregion

//region Fonts
$font-family-cormorant-garamond: Cormorant Garamond
$font-family-inter-tight: Inter Tight
$font-style-italic: italic
$font-style-normal: normal
$font-weight-bold: bold
$font-weight-medium: medium
$font-size-18: 18px
$font-size-40: 40px
$font-size-50: 50px
$character-spacing-0: 0px
$line-spacing-21: 21px
$line-spacing-49: 49px
$line-spacing-60: 60px
//endregion
