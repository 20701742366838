@import 'mixins'

//region Heading
// Heading - Desktop / Display / Bold
@mixin z-font-heading-desktop-display-bold
	font-size: 95px
	font-weight: 700
	line-height: 1em
	
	@include zi-media
		font-size: 61px
	
	@include zi-media('<=', mobile)
		font-size: 49px

// Heading - Desktop / H1 / Regular
@mixin z-font-heading-desktop-h1-regular
	font-size: 56px
	line-height: 1.2em
	font-weight: 400
	
	@include zi-media
		font-size: 49px
	
	@include zi-media('<=', mobile)
		font-size: 39px

// Heading - Desktop / H2
@mixin z-font-heading-desktop-h2
	font-size: 40px
	line-height: 1.2em
	font-weight: 400
	
	@include zi-media
		font-size: 39px
	
	@include zi-media('<=', mobile)
		font-size: 31px

// Heading - Desktop / H2 / Bold
@mixin z-font-heading-desktop-h2-bold
	@include z-font-heading-desktop-h2
	font-weight: 700

// Heading - Desktop / H2 / Bold
@mixin z-font-heading-desktop-h2-old-standard
	@include z-font-heading-desktop-h2
	font-family: "Old Standard TT", sans-serif

// Heading - Desktop / H3
@mixin z-font-heading-desktop-h3
	font-size: 39px
	line-height: 1.2em
	font-weight: 400
	
	@include zi-media
		font-size: 31px
	
	@include zi-media('<=', mobile)
		font-size: 25px

// Heading - Desktop / H4
@mixin z-font-heading-desktop-h4
	font-size: 31px
	line-height: 1.2em
	font-weight: 400
	
	@include zi-media
		font-size: 25px
	
	@include zi-media('<=', mobile)
		font-size: 20px
//endregion
//region Paragraph
@mixin z-font-old-standard
	font-family: "Old Standard TT", sans-serif
	font-size: 25px
	line-height: 1.4em

@mixin z-font-body
	font-size: 20px
	line-height: 1.2em

@mixin z-font-body-bold
	@include z-font-body
	font-weight: 700

@mixin z-font-body-large
	font-size: 25px
	font-weight: 400
	line-height: 1.2em

@mixin z-font-body-small
	font-size: 1rem
	font-weight: 400
	line-height: 1.2em
//endregion
