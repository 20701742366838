@use '../../abstract' as *

.why_choose_us
	@include z-padding
	
	@include z-grid
	place-items: center
	
	.top
		display: flex
		flex-direction: column
		gap: 2rem
		
		.why_choose_us_title
			@include z-font-heading-desktop-h2-bold
			color: $color-bleu-500
		
		.why_choose_us_subtitle
			@include z-font-heading-desktop-h2-bold
			color: $color-orange-500
			text-transform: uppercase
			
			@include z-title-margin-second-line
			
			@include zi-media('>')
				padding-left: 4rem
	
	.left
		grid-column: 2 / span 5
		
		display: flex
		flex-direction: column
		gap: 4rem
		
		.list
			display: flex
			flex-direction: column
			--gap-value: 4rem
			gap: var(--gap-value)
			
			> *:not(:last-child)
				position: relative
				
				&:after
					content: ''
					position: absolute
					inset: auto 0 calc(var(--gap-value) / -2) 0
					width: 100%
					pointer-events: none
					
					height: 1px
					background-color: $color-bleu-900
			
			.why_choose_us_item
				display: flex
				flex-direction: column
				gap: .5rem
				
				.title
					@include z-font-body-bold
					text-transform: uppercase
					color: $color-bleu-500
				
				.description
					color: $color-bleu-900
	
	.right
		grid-column: 8 / span 5
		
		position: relative
		width: 100%
		aspect-ratio: 1
		border-radius: var(--border-radius-value)
		overflow: hidden
		
		> .image
			position: absolute
			inset: 0
			width: 100%
			height: 100%
			object-fit: cover
			z-index: 0
			
			border-radius: var(--border-radius-value)
	
	@include zi-media
		display: flex
		flex-direction: column
		gap: 2rem
		
		@include zi-media('>', mobile)
			max-height: 100vh
			min-height: 100vh
			
			.bottom
				flex: 1
				
				display: flex
				flex-direction: column
				
				.left
					display: flex
					flex-direction: row
					--gap-value: 2rem
					
					> *:not(:last-child)
						&:after
							inset: 0 calc(var(--gap-value) / -2) 0 auto
							width: 1px
							height: 100%
				
				.right
					flex: 1
					
					aspect-ratio: unset
					height: 100%
					width: 100%
	
	@include zi-media('<=', mobile)
		.bottom
			width: 100%
			
			.why_choose_us_item
				position: relative
				display: flex
				flex-direction: column
				gap: 1rem
				
				height: auto
				
				.image
					margin-top: auto
					width: 100%
					height: auto
					aspect-ratio: 3 / 4
					
					object-fit: cover
					
					border-radius: var(--border-radius-value)
